import { gql } from '@/__generated__';
import { LinkFieldFragment } from '@/__generated__/graphql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable, makeNonNullableArray } from '@liquorice/utils';
import React from 'react';

export const LINK_FIELD_FRAGMENT = gql(`
  fragment LinkField on HyperLinkInterface {
    id: link # This is actually the html output, but HyperLinkInterface doesn't have an id, and we need on for the fragment
    ariaLabel
    classes
    isElement
    isEmpty
    linkText
    linkUri
    linkUrl
    linkValue
    newWindow
    target
    text
    title
    type
    url
    urlPrefix
    urlSuffix
  }
`);

export const parseLinkFieldFragment = (
  data: LinkFieldFragment
): React.ComponentPropsWithRef<'a'> => {
  // const isElement = data.isElement ?? false;

  const children = data.text ?? data.linkText;

  return {
    href: data.linkUrl ?? undefined,
    children,
    title: data.title ?? undefined,
    target: data.newWindow ? '_blank' : data.target ?? undefined,
    rel: data.newWindow ? 'noopener noreferrer' : undefined,
  };
};

export const parseLinkField = createFragmentArrayParser(LINK_FIELD_FRAGMENT, (data) => {
  const item = firstNonNullable(data);
  return item ? parseLinkFieldFragment(item) : null;
});

export const parseLinkFields = createFragmentArrayParser(LINK_FIELD_FRAGMENT, (data) => {
  return makeNonNullableArray(data.map(parseLinkField));
});
