'use client';

import { LINK_FIELD_FRAGMENT, parseLinkFields } from '@/lib/parsers/linkField';
import { withFragmentArray } from '@liquorice/gql-utils';
import { useState } from 'react';
import { DownChevron } from '../Atoms/Icons';
import ExpandingBlock from '../utility/ExpandingBlock';

export interface QuickLinksProps {
  label?: string;
}

export const QuickLinks = withFragmentArray(
  LINK_FIELD_FRAGMENT,
  (data, { label }: QuickLinksProps) => {
    const [quickLinksOpen, setQuickLinksOpen] = useState(false);
    const toggleQuickLinks = () => setQuickLinksOpen(!quickLinksOpen);
    const links = parseLinkFields(data);
    return (
      <div className="relative h-14 text-green-600">
        <div className="rounded-[28px] font-medium bg-sky-300 absolute top-0 left-0 z-10 max-w-xs">
          <button
            onClick={toggleQuickLinks}
            className="w-full flex items-center h-14 px-5 text-base justify-between">
            {label || <>I&apos;m looking for&hellip;</>}
            <div className="ml-2 flex items-center justify-center w-6 h-6 rounded-full bg-green-600">
              <DownChevron className={`stroke-sky-300 ${quickLinksOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>
          <ExpandingBlock expanded={quickLinksOpen}>
            <div className="flex flex-col gap-5 px-5 pb-6 text-base font-normal">
              {links.map((link, index) => (
                <a key={index} {...link} />
              ))}
            </div>
          </ExpandingBlock>
        </div>
      </div>
    );
  }
);
